import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroComponent } from './components/hero/hero.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { LeadershipComponent } from './pages/leadership/leadership.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { BigIdeasComponent } from './pages/big-ideas/big-ideas.component';
import { EventsComponent } from './pages/events/events.component';
import { ContactComponent } from './pages/contact/contact.component';
import { IonicModule } from '@ionic/angular';
import { PowerpointsComponent } from './components/powerpoints/powerpoints.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    TopnavComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    LeadershipComponent,
    ProgramsComponent,
    BigIdeasComponent,
    EventsComponent,
    ContactComponent,
    PowerpointsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
