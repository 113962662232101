import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  template: `<div [innerHTML]="data | safeHtml"></div>`,
})
export class AboutComponent implements OnInit {
  pageTitle: string;
  pageTitleTwo: string;
  heroImg: string;
  subText: string;
  class: string;

  constructor() {}

  ngOnInit() {
    this.pageTitle =
      'About Friendship Institute for the Advancement of Teaching';
    this.heroImg = '';
    this.subText = '';
  }
}
