<footer>
  <section>
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-md="6" size-sm="12">
          <img
            src="assets/fiat-logo-circle-white.png"
            alt="logo"
            style="width: 300px"
            (click)="scrollToTop()"
          />
          <ion-text style="display: block">
            <a href="https://friendshipusa.org" target="_blank" rel="nofollow">
              Powered by Friendship Education Foundation
            </a>
          </ion-text>
        </ion-col>
        <ion-col size="3" size-md="3" size-sm="6">
          <h4>Quick Links</h4>
          <a routerLink="/programs">Programs</a>
          <a routerLink="/events">Events</a>
          <a routerLink="/apply">Apply</a>
          <a routerLink="/contact-us">Contact Us</a>
        </ion-col>
        <ion-col size="3" size-md="3" size-sm="6">
          <h4>Contact us</h4>
          <p>
            <ion-icon name="pin" color="light"></ion-icon>

            <b>Washington DC</b> <br />
            <ion-icon name="phone-portrait-outline" color="light"></ion-icon>
            202-281-1700 <br />
            1351 Nicholson St NW Washington, DC 20011, USA
          </p>
          <p>
            <ion-icon name="pin" color="light"></ion-icon>
            <b>Little Rock AR</b> <br />
            <ion-icon name="phone-portrait-outline" color="light"></ion-icon
            >501-500-9332 <br />
            300 South Izard St Little Rock, AR 72201, USA
          </p>
        </ion-col>
      </ion-row>
      <!-- new row -->
      <hr style="border: solid 1px #2c65b0; background: #2c65b0" />
      <ion-row>
        <ion-col size="6" size-md="6" size-sm="12">
          <div style="display: flex; flex-direction: row">
            <p style="display: flex">Follow us:</p>
            <ion-toolbar
              color="secondary"
              style="--min-height: 10px !important"
            >
              <ion-buttons>
                <ion-button
                  *ngFor="let social of socials"
                  [fill]="social.fill"
                  [href]="social.href"
                  [target]="social.target"
                >
                  <ion-icon [name]="social.icon"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </div>
        </ion-col>
        <ion-col size="6" size-md="6" size-sm="12">
          &copy; {{ currentYear }} - Friendship Institute for the Advancement of
          Teaching
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</footer>
