<app-hero [pageTitle]="pageTitle"></app-hero>

<section>
  <div *ngFor="let p of programs">
    <ion-card>
      <ion-grid>
        <ion-row>
          <ion-col size-lg="3" size-md="3" size-sm="12" size-xs="12">
            <img [src]="p.img" [alt]="p.name" />
          </ion-col>
          <ion-col size-lg="9" size-md="9" size-sm="12" size-xs="12">
            <ion-card-content>
              <h2>{{ p.name }}</h2>
              <span [innerHtml]="p.content"></span>
              <span *ngIf="dates !== ''" [innerHtml]="p.dates"></span>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</section>
