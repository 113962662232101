<header>
  <section class="section-header">
    <ion-grid>
      <ion-row
        [ngClass]=""
        [class]="class"
        size-lg="4"
        size-md="3"
        size-sm="12"
        size-xs="12"
      >
        <ion-col class="bg-img">
          <!-- class="image-center" -->
          <img
            src="assets/book-gold.svg"
            alt=""
            style="width: 100px; position: absolute; z-index: 1; right: -70px"
          />
          <img loading="lazy" [src]="heroImg" />
        </ion-col>
        <ion-col size-lg="8" size-md="9" size-sm="12" size-xs="12">
          <img
            src="assets/rocket-gold.svg"
            alt=""
            style="width: 120px; position: absolute; z-index: 1; right: 0"
          />
          <h1 [class]="heroTitle">{{ pageTitle }}</h1>
          <h1 *ngIf="pageTitleTwo !== ''" [class]="heroTitle">
            {{ pageTitleTwo }}
          </h1>
          <p *ngIf="subText !== ''" class="bigger-text">{{ subText }}</p>

          <img
            src="assets/meteor.svg"
            alt=""
            style="
              width: 100px;
              float: right;
              margin-right: 2em;
              margin-top: 2em;
            "
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</header>
