<h2 style="text-align: center">Power Points</h2>
<ion-grid>
  <ion-row>
    <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="12">
      <img src="assets/power-point-1.svg" alt="" class="pp-icons" />
      <h4>Graduate from real-life programs</h4>
    </ion-col>
    <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="12">
      <img src="assets/power-point-2.svg" alt="" class="pp-icons" />
      <h4>Become an Expert</h4>
    </ion-col>
    <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="12">
      <img src="assets/power-point-3.svg" alt="" class="pp-icons" />
      <h4>Increase your knowledge</h4>
    </ion-col>
    <ion-col size-lg="3" size-md="3" size-sm="6" size-xs="12">
      <img src="assets/power-point-4.svg" alt="" class="pp-icons" />
      <h4>Liftoff your career</h4>
    </ion-col>
  </ion-row>
</ion-grid>
