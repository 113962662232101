import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {
  constructor() {}
  @Input() pageTitle?: string;
  @Input() pageTitleTwo?: string;
  @Input() heroImg?: string;
  @Input() bgImage?: string;
  @Input() subText?: string;
  @Input() class?: string;
  @Input() heroTitle?: string;
  ngOnInit(): void {}
}
