import { Component, OnInit } from '@angular/core';
import { programs } from '../../programs';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  template: '<div [innerHtml]="data | safeHtml"></div>',
})
export class EventsComponent implements OnInit {
  pageTitle: string;
  pageTitleTwo: string;
  heroImg: string;
  subText: string;
  class: string;
  programs = programs;
  constructor() {}

  ngOnInit() {
    this.pageTitle = 'Events';
  }
}
