<app-hero [pageTitle]="pageTitle" [heroImg]="heroImg" [subText]="subText">
</app-hero>
<section>
  <ion-grid>
    <ion-row>
      <ion-col size-lg="7" size-md="6" size-sm="8" size-xs="12">
        <h2>Our Philosophy</h2>
        <p>
          The Friendship Education Foundation (CMO) and the national affiliate
          charter school network of the Friendship Aspire Academy PCS (FAA) and
          Friendship PCS-Washington DC (FPCS) values our teacher workforce, and
          therefore are committed to the furtherance of our teacher's knowledge
          and skills in their esteemed profession. We believe teachers'
          effectiveness is dependent on the training they receive for teaching.
          Additionally, we believe that teachers should be respected as the
          professionals they are. And as professionals, we should strive not
          only to advance their craftsmanship but exhibit lifelong learning and
          commitment to the profession.
        </p>
        <p>
          Friendship's philosophy, shared by all its affiliates, is that all
          students can achieve at high levels when provided with a consistent
          message of achievement from all stakeholders, strong organizational
          leadership to promote and maintain rigorous instruction, access to
          high-quality teachers, and a research-based curriculum that engages
          learners. Friendship believes that a world-class education can create
          life-changing opportunities. Friendship takes a deliberate approach to
          recruit and hiring staff that is culturally diverse and broadly
          reflective of the population of the community we serve. We believe
          that this multi-level diversity breeds innovation in thought and
          creativity. The enhancement of our teaching force thereby enhances our
          student's access to innovative instruction
        </p>
      </ion-col>
      <ion-col size-lg="5" size-md="6" size-sm="4" size-xs="12">
        <img
          src="assets/images/joe-harris-2.png"
          alt="Joe Harris Public Speaking"
        />
        <img
          src="assets/yellow-circle.svg"
          alt="artistic shape"
          style="
            position: absolute;
            right: -30%;
            top: 0;
            z-index: -1;
            width: 100%;
          "
        />
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <ion-row>
      <ion-col>
        <h2>Core Values</h2>
        <ul>
          <li>
            <p>Produce professional teachers who can excel in any school</p>
          </li>
          <li><p>Create better schools</p></li>
          <li><p>Grow and nurture teachers</p></li>
          <li><p>Increase racial diversity in teaching</p></li>
          <li><p>Raise and strengthen the teaching profession</p></li>
          <li>
            <p>
              Reflect the values and professionalism consistent in high-quality
              schools
            </p>
          </li>
          <li>
            <p>
              Create a vehicle where ideas, best practices, and quality teachers
              can grow
            </p>
          </li>
          <li>
            <p>
              Increase the likelihood that all children encounter in their
              formal education a diversity of teachers
            </p>
          </li>
          <li><p>Create the very best teachers</p></li>
        </ul>
      </ion-col>
      <ion-col size-lg="5" size-md="6" size-sm="12" size-xs="12">
        <div
          style="
            width: 100%;
            display: flex;
            align-items: safe;
            justify-content: flex-start;
          "
        >
          <img src="assets/target.svg" alt="" style="width: 50px; right: 0" />
          <h2>Mission</h2>
        </div>
        <p>
          Our mission is to raise the capacity and level of the teaching
          profession and its ability to produce racially diverse teachers who
          are prepared and suited for teaching, well-trained, passionate about
          the profession, and form a new corps of great teachers, here in the
          U.S. and abroad.
        </p>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: safe;
            justify-content: flex-start;
          "
        >
          <img
            src="assets/power-point-2.svg"
            alt=""
            style="width: 50px; right: 0"
          />
          <h2>Vision</h2>
        </div>
        <p>
          Our vision is to go further than just training teachers. We seek to
          create better and more diverse schools that produce higher quality
          education and teachers the country needs. We affirm that developing
          better schools is the path toward strengthening the teaching
          profession.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-powerpoints></app-powerpoints>
</section>
