export const programs = [
  {
    name: 'Community Change Makers College Access Program',
    content: `<p><b>Objective:</b> Utilizing a self-actualization model for youth development, the program is centered around helping students who have demonstrated encountering barriers in attaining the skills:</p>
    <ol>
        <li><p>Identify problems, inequities, or barriers to success in their community</p></li>
        <li><p>Develop and pitch plans of action for shifting outcomes</p></li>
        <li><p>Executing strategies with the buy-in of classmates, the community, and local business/civic leaders.</p></li>
    </ol>
    <p>Goal: Through project based learning opportunities, the program aims to change students perceptions of being problem solvers, who have the ability to use education as a means of changing their community.</p> 
    `,
    dates: '',
    img: 'assets/images/fiat-2.jpg',
    upcoming: false,
  },
  {
    name: 'Praxis Prep Program',
    content: `<p><b>Objective:</b> Expand the academic content knowledge of teachers who come from K-16 experiences where they may have attended a core content course that either did not have a fully certified teacher, a long-term substitute, or teacher teaching out of their prepared field of study. In collaboration with several HBCU partner schools, the Praxis prep program aims to increase content knowledge while assisting teacher candidates in passing licensure exams.</p> `,
    dates: `<p><b>Program Dates</b>
    <p>Summer Program : Tu and Th, June 8 - July 2nd  1-3:30pm</p>
    <p>Cost: Free for those who qualify</p>
    `,
    img: 'assets/images/fiat-3.jpg',
    upcoming: true,
  },
  {
    name: 'Friendship’s IAT Podcast',
    content: `
    <p><b>Objective:</b> Broadcast discussions with thought leaders monthly on best practices for recruiting, supporting, and defining excellence in teaching. The show specifically aims to identify historical and systematics barriers to teaching and student success, explore solutions, and promote excellence in the field.
    <p><b>Goal:</b> Promote scholarship expanding awareness and opportunities for increasing the number of minority teachers specifically in the Arkansas Delta Region and ever growing footprint of communities served by Friendship Aspire Charter Schools. </p>
    `,
    dates: '',
    img: 'assets/images/fiat-5.jpg',
    upcoming: false,
  },
  {
    name: 'Friendship IAT Digital Cookout for New Teachers',
    content: ` 
        <p><b>Objective:</b>  The purpose of this bi-weekly open conversations is to allow for a forum for young, new, and developing minority teachers in the Arkansas Delta and the ever expanding communities served by Friendship Aspire  discuss concerns they see in the classroom, developing pedagogical strategies, and opportunities for developing their leadership potential within education. </p> `,
    dates: '',
    img: 'assets/images/fiat-4.jpg',
    upcoming: false,
  },
];
