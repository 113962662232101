import { Component, OnInit } from '@angular/core';
import { programs } from '../../programs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  template: `<div [innerHTML]="data | safeHtml"></div>`,
})
export class HomeComponent implements OnInit {
  pageTitle: string;
  pageTitleTwo: string;
  heroImg: string;
  subText: string;
  class: string;
  heroTitle: string;
  programs = programs;

  constructor() {}

  ngOnInit() {
    this.pageTitle = 'Advancing Teachers.';
    this.pageTitleTwo = 'Advancing Teaching.';
    this.heroImg = 'assets/images/fiat-6.png';
    this.subText =
      'It begins with you. And continues with us. Friendship IAT values our teacher workforce. And we are deeply committed to furthering their knowledge and skills in this esteemed profession. Because we believe teachers’ effectiveness is dependent on the training they receive and that teachers should be respected for the professionals they are.';
    this.class = '';
    this.heroTitle = '';
  }
  team = [
    {
      name: 'Joe Harris',
      jobTitle: 'CEO',
      img: 'assets/images/joe-harris.png',
      switchCase: '1',
      scrollId: 'joeHarris',
    },
    {
      name: 'Phong Tran',
      jobTitle: 'Southern Region Superintendent',
      img: 'assets/images/tran.png',
      switchCase: '2',
      scrollId: 'phongTran',
    },
    {
      name: 'Dr. Ray Ivey, EdD',
      jobTitle: 'Senior Program Director',
      img: 'assets/images/dr-ray-ivey.png',
      switchCase: '3',
      scrollId: 'DrRayIvey',
    },
    {
      name: 'Virginia Perry',
      jobTitle: 'Director of Development',
      img: 'assets/images/virginia-perry.png',
      switchCase: '4',
      scrollId: 'virginiaPerry',
    },
  ];
}
