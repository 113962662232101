import { Component, OnInit } from '@angular/core';
import { programs } from '../../programs';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  template: `<div [innerHTML]="data | safeHtml"></div>`,
})
export class ProgramsComponent implements OnInit {
  pageTitle: string;
  pageTitleTwo: string;
  heroImg: string;
  subText: string;
  class: string;
  programs = programs;
  constructor() {}

  ngOnInit() {
    this.pageTitle = 'Our Programs';
  }
}
