<app-hero [pageTitle]="pageTitle"></app-hero>

<section>
  <h2>FIAT Board of Directors</h2>
  <ion-grid>
    <ion-row *ngFor="let b of board">
      <ion-col size-lg="4" size-md="5" size-sm="5" size-xs="12">
        <img
          [src]="b.img"
          [alt]="b.name"
          style="padding: 2em; max-width: 500px"
        />
      </ion-col>
      <ion-col size-lg="8" size-md="7" size-sm="7" size-xs="12">
        <h3>{{ b.name }}</h3>
        <h4 *ngIf="b.title">{{ b.title }}</h4>
        <p [innerHtml]="b.bio"></p>
      </ion-col>
      <hr />
    </ion-row>
  </ion-grid>
</section>

<div style="background-color: #6aa3d4">
  <section>
    <img src="assets/meteor.svg" alt="" style="width: 100px" />
    <h2>FIAT Leadership</h2>
    <img
      src="assets/rocket-gold.svg"
      alt=""
      style="width: 100px; position: absolute; right: 5%"
    />
    <ion-grid>
      <ion-row *ngFor="let t of team">
        <ion-col size-lg="4" size-md="5" size-sm="5" size-xs="12">
          <div [attr.id]="t.scrollId"></div>
          <img
            [src]="t.img"
            [alt]="t.name"
            style="padding: 2em; max-width: 500px"
          />
        </ion-col>
        <ion-col size-lg="8" size-md="7" size-sm="7" size-xs="12">
          <h3>{{ t.name }}</h3>
          <h4 *ngIf="t.title">{{ b.title }}</h4>
          <p [innerText]="t.bio"></p>
        </ion-col>
        <hr />
      </ion-row>
    </ion-grid>
  </section>
</div>
