import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  template: '<div [innerHtml]="data | safeHtml"></div>',
})
export class ContactComponent implements OnInit {
  contactForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
  });

  pageTitle: string;
  constructor(private http: HttpClient) {} // private mailService: MailService

  ngOnInit() {
    this.pageTitle = 'Contact Us';
  }

  onSubmit() {
    console.warn('Friendship Contact Form was submitted');
    const data = this.contactForm.value;
    JSON.stringify(data);
    console.log(data);
    this.http
      .post(
        'http://thefriendshipinstitute.org/friendship-contact-script.php',
        data
      )
      .subscribe(
        (res: Response) => {
          console.log(res.json);
        },
        (err) => {
          console.log('- Error while submitting the form -');
        }
      );
  }
}
