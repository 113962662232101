import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit {
  resNav: boolean;

  [x: string]: any;

  //constructor() { }
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {}

  openNav() {
    console.log('menu clicked');
    this.resNav = !this.resNav;
    const scrollNav = document.getElementById('responsive');
    if (this.resNav == true) {
      scrollNav.classList.remove('nav-span');
      scrollNav.classList.remove('hide-nav');
      scrollNav.classList.add('show-nav');
    } else {
      scrollNav.classList.remove('show-nav');
      scrollNav.classList.add('hide-nav');
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.documentElement.scrollTop > 20) {
      document
        .getElementById('top-logo-switch')
        .classList.add('top-logo-smaller');
      document.getElementById('top-logo-switch').classList.remove('top-logo');
    } else if (document.documentElement.scrollTop < 20) {
      document
        .getElementById('top-logo-switch')
        .classList.remove('top-logo-smaller');
      document.getElementById('top-logo-switch').classList.add('top-logo');
    }
  }
}
