import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BigIdeasComponent } from './pages/big-ideas/big-ideas.component';
import { ContactComponent } from './pages/contact/contact.component';
import { EventsComponent } from './pages/events/events.component';
import { LeadershipComponent } from './pages/leadership/leadership.component';
import { ProgramsComponent } from './pages/programs/programs.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'big-ideas', component: BigIdeasComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'leadership', component: LeadershipComponent },
  { path: 'programs', component: ProgramsComponent },
  { path: 'events', component: EventsComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
