import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-ideas',
  templateUrl: './big-ideas.component.html',
  styleUrls: ['./big-ideas.component.scss'],
  template: `<div [innerHTML]="data | safeHtml"></div>`,
})
export class BigIdeasComponent implements OnInit {
  pageTitle: string;
  constructor() {}

  ngOnInit(): void {
    this.pageTitle = 'Big Ideas';
  }
}
