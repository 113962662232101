import { Component, OnInit } from '@angular/core';

export interface Social {
  icon: string;
  href: string;
  fill: string;
  target: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();

  socials: Social[] = [
    {
      icon: 'mail',
      href: '/contact-us',
      fill: 'clear',
      target: '',
    },
    {
      icon: 'logo-facebook',
      href: '',
      fill: 'clear',
      target: '_blank',
    },
    {
      icon: 'logo-twitter',
      href: '',
      fill: 'clear',
      target: '_blank',
    },
    {
      icon: 'logo-instagram',
      href: '',
      fill: 'clear',
      target: '_blank',
    },
    {
      icon: 'logo-linkedin',
      href: '',
      fill: 'clear',
      target: '_blank',
    },
  ];
  constructor() {}

  ngOnInit() {}

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
