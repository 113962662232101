<nav class="topnav" id="myTopnav">
  <section class="section-nav">
    <a routerLink="/" class="first-icon" style="padding: 0px; margin: 0px">
      <img
        src="assets/fiat-logo-primary.png"
        class="top-logo"
        id="top-logo-switch"
        alt="FIAT"
      /><!-- FIAT Logo -->
    </a>
    <a style="cursor: pointer" class="icon" (click)="openNav()">
      <ion-icon name="menu-outline"></ion-icon>
    </a>
    <span class="nav-span" id="responsive">
      <a routerLink="/" class="nav-items underline">Home</a>
      <a routerLink="/about" class="nav-items underline">About Us</a>
      <a routerLink="/leadership" class="nav-items underline">Leadership</a>
      <a routerLink="/programs" class="nav-items underline">Programs</a>
      <!-- <a routerLink="/big-ideas" class="nav-items underline">Big Ideas</a> -->
      <a routerLink="/events" class="nav-items underline">Events</a>
      <a routerLink="/contact" class="nav-items nav-item-bg">Contact</a>
    </span>
  </section>
</nav>
