import { Component, OnInit } from '@angular/core';
import { Router, Scroll, Event } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['./leadership.component.scss'],
  template: `<div [innerHTML]="data | safeHtml"></div>`,
})
export class LeadershipComponent implements OnInit {
  pageTitle: string;
  pageTitleTwo: string;
  heroImg: string;
  subText: string;
  class: string;
  headerOffset = 100;

  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          viewportScroller.setOffset([0, this.headerOffset]);
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.setOffset([0, this.headerOffset]);
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.setOffset([0, this.headerOffset]);
        }
      });
  }

  ngOnInit() {
    this.pageTitle = 'Leadership';
    this.heroImg = '';
    this.subText = '';
  }
  board = [
    {
      name: 'Donald Hense',
      title: '',
      bio: `
      Mr. Hense founded Friendship Public Charter School in 1997. Thanks to Mr. Hense’s vision and relentless insistence on excellence, thousands of District students have achieved academic success with the nation’s best educators and administrators directing students to college and a rewarding future. Friendship Public Charter School now operates six public charter school campuses in Washington, DC serving approximately 4,000 students. Mr. Hense is a member of the Board of Directors of the Center for Education Reform. He previously served as Director of Development of the Children’s Defense Fund; National Vice President for Development of the National Urban League in New York; Vice President for Development of Prairie View A&M University and Texas A&M University System; and Director of Governmental Relations at Dartmouth College, Boston University, and Howard University. He is also the co-founder of the Bridges to Friendship Initiative, which was highlighted by Vice President Gore at the White House Summit on Community Empowerment as a model initiative.
      <br><br>In June of 2011, Mr. Hense was inducted into the Hall of Fame of the National Alliance of Public Charter Schools. He was among selected Washingtonians showcased in the Choosing to Participate: Portrait of Courage Exhibition, which highlighted how civic choices shape individuals as they grow into active community members and global citizens. In celebration of Black History Month, Mr. Hense received the Amtrak Pioneer Award from the Washington Wizards to honor African Americans who have made outstanding contributions to the greater DC community. Mr. Hense received one of the most prestigious awards offered by Morehouse College, the Bennie Award for Service, which honors alumni who have performed great acts of service to others. He is a graduate of Morehouse College and attended graduate school at Stanford University where he was a Ford Foundation Fellow. He was a Rockefeller Intern in Economics at Cornell University; a Merrill Scholar to the University of Ghana; and a Lecturer at the University of California, Berkeley. In his capacity as Chairman of the board of Friendship Education Foundation, Donald Hense works an average of four hours per week and attends to the proper governance, oversight, and strategic direction of the organization.
      `,
      img: 'assets/images/board/donald-hense.png',
    },
    {
      name: 'Sue White',
      title: '',
      bio: `After earning her BS degree in Mathematics from Morgan State University, Ms. White began her career in public education teaching mathematics and has been a consistent advocate for project-based learning. In 1987, she received the White House Presidential Award for Excellence in Teaching Mathematics, the same year she became Mathematics Instructional Supervisor for the District of Columbia Public Schools. She has led numerous national STEM projects with organizations such the Carnegie Institution of Washington and the National Science Foundation. In 2011, she became the Head of School for Howard University’s Public Charter Middle School of Mathematics and Science.  Since stepping down from that position, she has advised and worked with Friendship Public Charter Schools on STEM education areas. `,
      img: 'assets/images/board/sue-white.png',
    },
    {
      name: 'Barbara Nophlin',
      title: '',
      bio: `Ms. Nophlin began her education career as Child Development Coordinator for the United States Navy/Marine Corps before becoming early Childhood Coordinator and Instructional Support Specialist for the District of Columbia Public Schools, principal of a DC public elementary school, and then principal of a DC public charter school, and 9i Director of the Office of Policy, Research and Analysis for the State Education Office in the Executive Office of the DC Mayor. She served concurrently as an Adjunct Professor, Trinity Washington University, teaching in the Master of Science in Administration program. Before joining the Friendship Public Charter School team as a consultant helping new principals, she served for five years as the head of the Paul Public Charter School in, Washington.`,
      img: 'assets/images/board/ms-nophlin.png',
    },
    {
      name: 'Dr. Greg Prince',
      title: '',
      bio: `Dr. Gregory S. Prince, Jr. (Vice Chair of the Friendship Board, Co-chair of the Board’s Performance and Facilities Committees) became Hampshire College's fourth president in 1989 and retired in 2005.During his career in education he has served as president of Five Colleges, Inc. and the Yale China Association, chair of the board of the Association of Independent Colleges and Universities of Massachusetts (AICUM), vice-chair of the Council on Racial and Ethnic Justice of the American Bar Association and on the board of directors of the New England Association of Schools and Colleges (NEASC).`,
      img: 'assets/images/board/dr-greg-prince.png',
    },
    {
      name: 'Connie Spinner ',
      title: '',
      bio: `Ms. Spinner’s 25 year DC public schools career included being a teacher, master teacher, assistant principal Director of Parent Involvement, Corporate & Community Support and Assistant Director for Systemic Educational Change.   Leaving the DC Pubic Schools, she became Education Division director for Public Education Network, Executive Director of DC Children and Youth Investment Trust Corporation and then State Education Officer for the Mayor before becoming Special Assistant to the Provost of the University of the District of Columbia and then Dean for Workforce Development and Community Outreach. She currently is the Founding head of Community College Preparatory Academy Public Charter School in Washington.`,
      img: 'assets/images/board/connie-spinner.png',
    },
  ];

  team = [
    {
      name: 'Joe Harris',
      jobTitle: 'CEO',
      img: 'assets/images/joe-harris.png',
      bio: `Joe Harris is an applauded educational/community development thought leader with substantial experience in nonprofit management that includes over 24 years positively impacting children, families and businesses. He has combined business acumen and community service experience with a dedication to education and has helped generate 20 new schools — and re-generate failing schools — that are now serving over 7000 students in four states. Harris is directly responsible for raising proficiency scores for thousands of disadvantaged or minority students and eliminating barriers to educational opportunities. He has fostered strategic and innovative partnerships with key government, business, and charitable entities – including, most recently, the Walton Family Foundation.`,
      scrollId: 'joeHarris',
    },
    {
      name: 'Phong Tran',
      jobTitle: 'Southern Region Superintendent',
      img: 'assets/images/tran.png',
      bio: `Phong Tran believes that all children can learn. This belief is his motivating force to improve educational outcomes for all children in a safe, responsible, and respectful school environment. Tran was previously Deputy Chief Executive Officer of four high-quality charter schools in New Orleans. There, he successfully increased student achievement within a highly diverse student population where students spoke a minimum of three languages. Tran is well versed in curriculum, instruction, data analysis, and intervention strategies.
      <br><br>Tran was integral in helping his previous school gain recognition as a High-Quality Charter School from the United States Department of Education and gain recognition for increasing student achievement, while bridging the sub-group achievement gap, by the Coalition of Schools Educating Boys of Color. Tran and his team were the first in the Orleans Parish school district to acquire a failing school and increase student achievement in its first year of operations.
      <br><br>Tran has brought his expertise and years of experience to Arkansas for the sole mission of bridging the achievement gap between subgroups while increasing overall student achievement as he did in New Orleans.
      `,
      scrollId: 'phongTran',
    },
    {
      name: 'Dr. Ray Ivey, EdD',
      jobTitle: 'Senior Program Director',
      img: 'assets/images/dr-ray-ivey-2.png',
      bio: `Dr. Ray Ivey is an exceptional education reform leader with the unique experience of having spent the last 10 years blending his role as an urban teacher educator with responsibilities for workforce development and youth development programs. This combination of experiences brings an awesome array of skills to the task of developing and recruiting new talent and developing existing talent within the teaching profession. These experiences and research interests developed in his USC doctoral program involving Trauma-Informed Instruction, Black Male Student Achievement, student/teacher engagement and fiscal efficiency for school turnaround provide a strong assessment framework for his projects.`,
      scrollId: 'DrRayIvey',
    },
    {
      name: 'Virginia Perry',
      jobTitle: 'Director of Development',
      img: 'assets/images/virginia-perry.png',
      bio: `Virginia Perry is a Director of Development who works with campus, district, and Charter Management Organization administrators to grow their capacity to develop students. After over two decades working in public education at various levels, Virginia knows what it takes to start successful schools, inspire staff, and maintain alliances to keep schools in compliance and solvent. At the heart of every initiative that involves schools is the benefit to the children served. Virginia has started schools from the community and business support, facility acquisition, and application process to the curriculum and staff selection process. She has turned around schools in challenging environments and established in-district partnerships with charter and traditional public school entities. In addition to being a licensed teacher and school leader, Virginia has experience in marketing and public relations. Virginia holds a BA in English, a Masters in Educational Administration, and is pursuing a doctorate in Educational Leadership.`,
      scrollId: 'virginiaPerry',
    },
  ];
}
