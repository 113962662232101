<div style="background-color: #5da5e1">
  <div class="home-hero">
    <div class="hero-txt">
      <h1
        style="
          color: #ffffff;
          text-align: center !important;
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.26);
        "
      >
        Friendship Institute for the Advancement of Teaching
      </h1>
      <h2 style="text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4); color: #a8d051">
        Liftoff your Education Career
      </h2>
    </div>
  </div>
  <app-hero
    [pageTitle]="pageTitle"
    [pageTitleTwo]="pageTitleTwo"
    [heroImg]="heroImg"
    [subText]="subText"
    [class]="class"
    [heroTitle]="heroTitle"
  >
  </app-hero>
</div>
<img src="assets/rocket-liftoff.svg" alt="" style="width: 100%" />
<section style="width: 90%; margin: auto; margin-top: -15%">
  <ion-grid>
    <ion-row>
      <ion-col size-lg="5" size-md="5" size-sm="5" size-xs="12">
        <img
          src="assets/images/leaders.png"
          alt="FIAT leaders"
          style="max-width: 500px"
        />
      </ion-col>
      <ion-col size-lg="7" size-md="7" size-sm="7" size-xs="12">
        <div
          style="
            width: 100%;
            display: flex;
            align-items: safe;
            justify-content: flex-start;
          "
        >
          <img src="assets/target.svg" alt="" style="width: 50px; right: 0" />
          <h2>Mission</h2>
        </div>
        <p>
          Our mission is to raise the capacity and level of the teaching
          profession and its ability to produce racially diverse teachers who
          are prepared and suited for teaching, well-trained, passionate about
          the profession, and form a new corps of great teachers, here in the
          U.S. and abroad.
        </p>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: safe;
            justify-content: flex-start;
          "
        >
          <img
            src="assets/power-point-2.svg"
            alt=""
            style="width: 50px; right: 0"
          />
          <h2>Vision</h2>
        </div>
        <p>
          Our vision is to go further than just training teachers. We seek to
          create better and more diverse schools that produce higher quality
          education and teachers the country needs. We affirm that developing
          better schools is the path toward strengthening the teaching
          profession.
        </p>
        <button routerLink="/about">Learn More</button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-powerpoints></app-powerpoints>

  <img src="assets/meteor.svg" alt="" style="width: 100px" />
</section>

<div class="wave-bg">
  <section>
    <div
      style="
        width: 100%;
        display: flex;
        align-items: safe;
        justify-content: flex-end;
      "
    >
      <h2 style="text-transform: capitalize; text-align: right">Leadership</h2>
      <img src="assets/meteor.svg" alt="" style="width: 100px; right: 0" />
    </div>

    <ion-grid>
      <ion-row>
        <ion-col
          *ngFor="let t of team"
          size-lg="3"
          size-md="4"
          size-sm="6"
          size-xs="6"
        >
          <img
            [src]="t.img"
            alt=""
            class="img leadership"
            (click)="selection.value = t.switchCase"
            tabindex="0"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
    <input type="hidden" #selection />
    <div [ngSwitch]="selection.value">
      <div *ngSwitchDefault><p>Click or Tap on each image</p></div>
      <div *ngFor="let t of team">
        <div *ngSwitchCase="t.switchCase">
          <h2>{{ t.name }}</h2>
          <h3>{{ t.jobTitle }}</h3>
          <a routerLink="/leadership" [fragment]="t.scrollId">
            <button>See Full Bio</button>
          </a>
        </div>
      </div>
    </div>

    <img src="assets/meteor.svg" alt="" style="width: 100px" />
  </section>
</div>
<div style="background-color: #5da5e1; padding: 1em">
  <section>
    <h2>Our Programs</h2>
    <ion-grid>
      <ion-row>
        <ion-col
          size-lg="4"
          size-md="6"
          size-sm="6"
          size-xs="12"
          *ngFor="let p of programs"
        >
          <ion-card>
            <img [src]="p.img" alt="" />
            <ion-card-header>
              <ion-card-title>
                {{ p.name }}
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <button routerLink="/programs">Learn More</button>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</div>
