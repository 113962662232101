<app-hero [pageTitle]="pageTitle"></app-hero>

<section>
  <h2>Want To Learn More?</h2>
  <h3>Just fill out the form below!</h3>
  <ion-card>
    <ion-card-content>
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating" color="primary">
                  Enter First Name
                </ion-label>
                <ion-input color="primary" formControlName="firstName">
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="floating" color="primary">
                  Enter Last Name
                </ion-label>
                <ion-input color="primary" formControlName="lastName">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating" color="primary">
                  Enter Email
                </ion-label>
                <ion-input
                  color="primary"
                  formControlName="email"
                  inputmode="email"
                >
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating" color="primary">
                  Enter Message
                </ion-label>
                <ion-textarea
                  auto-grow="true"
                  color="primary"
                  formControlName="message"
                >
                </ion-textarea>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button size="large" type="submit" [disabled]="contactForm.invalid">
          submit
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
</section>
